module.exports = [
  {
    key: 'Custom Ranges',
    items: [
      { id: 'custom', name: 'Custom date range' },
      { id: 'months', name: 'Range of months' },
      { id: 'days', name: 'Range of days' }
    ]
  },
  {
    key: 'Time Ranges',
    items: [
      { id: 'time-all', name: 'Time Range (all week)' },
      { id: 'time-mon', name: 'Monday' },
      { id: 'time-tue', name: 'Tuesday' },
      { id: 'time-wed', name: 'Wednesday' },
      { id: 'time-thu', name: 'Thursday' },
      { id: 'time-fri', name: 'Friday' },
      { id: 'time-sat', name: 'Saturday' },
      { id: 'time-sun', name: 'Sunday' }
    ]
  }
]
